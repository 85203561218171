/* start glouble rule  */

:root {
  --main-color: #011339;
  --secondary-color: #f8f8f8;
  --third-color: #48dcae;
}

.font {
  font-family: 'Almarai', sans-serif;
}

body {
  background-color: var(--main-color) !important;
}

::selection {
  background-color: #021c53;
  color: white;
}

/* end glouble rule  */

/* start navbar */

.logo {
  width: 130px;
}

.nav {
  background-color: var(--secondary-color);
}

.parent-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Characters a {
  color: var(--third-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
  padding: 5px;
  position: relative;
  text-decoration: none;
}

.Characters a::after {
  content: "";
  position: absolute;
  background-color: var(--main-color);
  width: 85%;
  height: 1px;
  left: 8%;
  bottom: 0;
}

.Characters a:hover {
  background-color: var(--main-color);
  border-radius: 8px;
  color: var(--third-color);
}



/* end navbar */

/* start landing  */

.land {
  height: 540px;
  position: relative;
  min-height: 670px;
}

.content-landing {
  position: absolute;
  text-align: center;
  left: 20px;
  top: 0;
  color: white;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-style {
  background-color: var(--third-color) !important;
  border: none !important;
  font-weight: bold !important;
  transition: 0.5s !important;
}

.btn-style:hover {
  transform: scale(1.09);
  background-color: #3ec096 !important;
}

@media (min-width: 678px) and (max-width: 820px) {
  .land {
    min-height: 1000px;
  }
}


@media (max-width: 820px) {
  .land img {
    display: none;
  }
  .land {
    background-color: var(--main-color);
    height: 650px;
  }
  .content-landing {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .logo {
    width: 100px;
  }
}

@media (min-width: 992px) {
  .content-landing {
    width: 380px;
  }
  .land {
    height: 400px;
  }
}

@media (min-width: 1200px) {
  .content-landing {
    width: 500px;
  }
  .land {
    height: 540px;
  }
}

/* end landing  */

/* start footer  */

.footer {
  background-color: var(--secondary-color);
  /* height: 100px; */
  /* position: absolute;
  bottom: 0;
  width: 100%; */
}

.footer span {
  color: #04a7f1;
}

.icons {
  font-size: 25px;
  text-align: center;
}

.icons i {
  margin-left: 20px;
  margin-top: 20px;
}

.facebook {
  transition: 0.5s;
  color: #3b5999;
}

.facebook:hover {
  transform: scale(1.25);
}

.twitter {
  transition: 0.5s;
  color: #1da1f3;

}

.twitter:hover {
  transform: scale(1.25);
}

.instagram {
  transition: 0.5s;
  color: #ff0171;
}

.instagram:hover {
  transform: scale(1.25);
}

.copyright {
  text-align: center;
}

@media (max-width: 768px) {
  .icons {
    font-size: 18px;
    padding-top: 20px;
  }
  .copyright {
    font-size: 14px;
    padding-bottom: 10px;
  }
}


/* end footer  */

/* start form validation  */

.form-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 400px;
  border-radius: 15px;
  background-color: var(--secondary-color);
  position: relative;
  right: 50%;
  margin-right: -250px;
  margin-top: 71px;
  margin-bottom: 71px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 150, 0.75);
}

.input {
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: white;
  width: 300px;
  box-shadow: 1px 4px 4px 1px rgba(85, 85, 85, 0.25);
}

@media (min-width: 992px) {
  .form-input {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .form-input {
    height: 300px;
    margin-top: 250px;
    margin-bottom: 430px;
  }
}
@media (min-width: 1200px) {
  .form-input {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .form-input {
    width: 300px;
    margin-right: -150px;
    margin-top: 122px;
    margin-bottom: 122px;
  }
  .input {
    width: 250px;
  }
}

/* end form validation  */


/* start model pop-up  */


.model-container {
  text-align: center;
  padding: 10px;
  max-width: 500px;
  width: 100%;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: white;
  /* border-radius: 15px; */
  box-shadow: 0 0 0 600px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.model-container::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: var(--third-color);
  top: 0;
  right: 0;
}

.model-container::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: var(--third-color);
  top: 0;
  left: 0;
}

@media(min-width:992px) and (max-width:1199px) {
  .model-container {
    height: 270px;
  }
}

@media (max-width:767px) {
  .model-container {
    width: 85%;
    height: 370px;
  }
}


/* end model pop-up  */


/* start Question-component */


.Question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 750px;
  height: 350px;
  border-radius: 15px;
  background-color: var(--secondary-color);
  position: relative;
  right: 50%;
  margin-right: -375px;
  margin-top: 96px;
  margin-bottom: 96px;
  padding: 20px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 150, 0.75);
}

.numQ {
  position: absolute;
  top: 15px;
  right: 0;
}

@media (max-width: 767px) {
  .Question-component {
    width: 350px;
    height: 350px;
    margin-right: -175px;
    margin-top: 150px;
    margin-bottom: 150px;
  }
}

@media(min-width:992px) and (max-width:1199px) {
  .Question-component {
    width: 600px;
    height: 280px;
  }
}

@media(min-width:768px) and (max-width:1199px) {
  .Question-component {
    width: 600px;
    height: 280px;
    margin-top: 250px;
    margin-bottom: 450px;
    margin-right: -300px;
  }
}

/* end Question-component */

/* start characters-component */

.parent-characters{
  width: 1200px;
  position: relative;
  right: 50%;
  margin-right: -600px;
  /* height: auto; */
}

.description {
  padding: 0 20px 20px;
}

.symptoms {
  padding: 0 20px 20px;
}

@media (max-width: 767px) {
  .parent-characters {
    width: 300px;
    right: 50%;
    margin-right: -150px;
    margin-bottom: 60px;
    /* height: calc(100vh - 225px); */
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .parent-characters {
    width: 700px;
    right: 50%;
    margin-right: -350px;
    margin-bottom: 400px;
  }
}

/* end characters-component */

/* start Toast Container */



@media (max-width: 767px) {
  .Toastify__toast-container--top-right {
    width: 250px !important;
    margin-top: 10px !important;
  }
}


/* start Toast Container */
